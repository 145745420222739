<template>
  <div class="mobile-wrap">
    <!-- <div id="app"> -->
    <div>
      <div class="pic">
        <img  :src="eilisProfile.logo" style="margin-top: -30%;" alt="louisa logo"  width="100%">
        <!-- <section>
          <svg id="curve" data-name="圖層 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 616.67 440">
          <path class="cls-1" d="M604.78-8.44s-22.1,58-110.48,70.91c-71.58,10.44-333,22-371.61,191.8C84.53,421.89-11.89,431.56-11.89,431.56v-440Z" transform="translate(11.89 8.44)"/></svg>
        </section> -->
      </div>
      <!-- Token -->
      <!-- <cube-textarea class="item" :placeholder="transform" disabled>
      </cube-textarea> -->

      <!-- 電話 -->
      <cube-input  class="item" style="border: 1px solid #eee; width: 88% ;font-size: 17px;" v-model="info.phone"  :placeholder="$t('Login.authHint') + countries.filter(el => el.value === countryCode).map(el => el.example)[0]" disabled>
      </cube-input>

      <!-- 輸入姓名 -->
      <!-- <cube-input   class="item"  v-model="name" placeholder="輸入姓名" ></cube-input> -->
      <cube-form :model="model" >
        <cube-form-group>
          <cube-form-item  class="item" style="border: 1px solid #eee; width: 80%" :field="fields[0]"></cube-form-item>
          <cube-form-item  class="item" style="border: 1px solid #eee; width: 80%" :field="fields[1]" >
            <button style="font-size: 16px; border: none; background: none; color: inherit; outline: none;" @click="showDatePicker">{{model.dateValue || '請選擇'}}</button>
          </cube-form-item>
          <cube-form-item  v-if="this.eilisProfile.address !== 0" class="item" style="border: 1px solid #eee; width: 80%" :field="fields[2]">
            <button style="font-size: 16px; border: none; background: none; color: inherit; outline: none;" @click="showAreaPicker">{{model.area || '請選擇區域'}}</button>
          </cube-form-item>
          <cube-form-item  v-if="this.eilisProfile.address !== 0" class="item" style="border: 1px solid #eee; width: 80%" :field="fields[3]"></cube-form-item>
          <cube-form-item  v-if="this.eilisProfile.email !== 0" class="item" style="border: 1px solid #eee; width: 80%" :field="fields[4]" title="請選擇"></cube-form-item>
          <cube-form-item  v-if="this.eilisProfile.gender !== 0" class="item" style="border: 1px solid #eee; width: 80%" :field="fields[5]" title="請選擇"></cube-form-item>
          <cube-form-item  v-if="this.eilisProfile.shop !== 0 && this.city.length > 5" class="item" style="border: 1px solid #eee; width: 80%" :field="fields[6]" title="請選擇">
             <button type="button" style="font-size: 16px; border: none; background: none; color: inherit; outline: none;" @click="showCityPicker">{{ model.city || '請選擇分店縣市'}}</button>
          </cube-form-item>
          <cube-form-item  v-if="this.eilisProfile.shop !== 0 && this.city.length > 5" class="item" style="border: 1px solid #eee; width: 80%" :field="fields[7]" title="請選擇">
             <button type="button" style="font-size: 16px; border: none; background: none; color: inherit; outline: none;" @click="showDistrictPicker">{{ model.district || '請選擇分店區域'}}</button>
          </cube-form-item>
          <cube-form-item  v-if="this.eilisProfile.shop !== 0 || shopDetail" class="item" style="border: 1px solid #eee; width: 80%" :field="fields[8]" title="請選擇">
             <button type="button" style="font-size: 16px; border: none; background: none; color: inherit; outline: none;" @click="showShopPicker(model.district)" :disabled="shopDetail">{{ shopDetail ? shopDetail.shopName  : model.saleroom || '請選擇分店'}}</button>
          </cube-form-item>
          <cube-form-item  v-if="this.eilisProfile.voucher !== 0" class="item" style="border: 1px solid #eee; width: 80%" :field="fields[9]" title="請選擇"></cube-form-item>
          <cube-form-item  v-if="this.eilisProfile.remarks !== 0" class="item" style="border: 1px solid #eee; width: 80%" :field="fields[10]" title="請選擇"></cube-form-item>
          <!-- <cube-form-item  class="item" style="border: 1px solid #eee; width: 80%" :field="fields[7]" title="請選擇"></cube-form-item>
          <cube-form-item  class="item" style="border: 1px solid #eee; width: 80%" :field="fields[8]" title="請選擇"></cube-form-item>
          <cube-form-item  class="item" style="border: 1px solid #eee; width: 80%" :field="fields[9]" title="請選擇"></cube-form-item> -->
        </cube-form-group>
      </cube-form>

      <br>
      <!-- <cube-button class="item"  :light="true" @click="login">確認</cube-button> -->
      <cube-button id="sign-in-button" class="item" :light="true"  style="transform: translateY(0px);" @click="openTerms" >隱私與使用者條款</cube-button>

      <cube-checkbox style="margin-left:110px; top:20px; font-size: 15px;" v-model="checked" position="center" shape="square" :hollow-style="true">
        <span style="letter-spacing: 3px;">我已閱讀並同意</span>
      </cube-checkbox>
      <div class="scroll-list-wrap" v-if="openLaw" style="padding: 10px,">
        <div style="width: 91%; height: 100vh; padding: 20px; margin: 0px auto; overflow-y: auto;">
          <iframe :src="brandData.privacyUrl"
            height="80%"
            width="100%"
            frameborder="0"
            style="border:0"
            loading="lazy">
          </iframe>
          <!-- <ul>
            <li>個人資料蒐集、處理、利用書面同意書</li>
            <li>為遵守個人資料保護法規定，並保障當事人之權利，謹依法告知下列事項：</li>
            <li>1.	品牌會員：{{brand}} Taiwan</li>
            <li>2.	蒐集目的：為提供{{brand}}社群會員卡專案用途。</li>
            <li>3.	個人資料之類別：姓名、電話、地址、生日或其他相關基本資料等。</li>
            <li>4.	個人資料利用之期間、地區、對象及方式：對於您提供之個人資料，將於執行社群會員卡專案用途期間，依個人資料保護法之相關規定使用您的個人資料，不另做其他用途。</li>
            <li>5.	依個人資料保護法第3條規定，當事人可行使以下權利：</li>
            <li>(1) 查詢或請求閱覽。<br>(2) 請求製給複製本。<br>(3) 請求補充或更正。<br>(4) 請求停止蒐集、處理及利用。<br>(5) 請求刪除。<br>若有上述需求，請與{{brand}} Taiwan連繫(聯絡資訊: cs@mail.ruentex.com.tw)。另依個人資料保護法第14條規定，查詢或請求閱覽個人資料或製給複製本者，{{brand}} Taiwan得酌收必要成本費用。</li>
            <li>6.	您得自由選擇是否提供相關個人資料，惟您若拒絕提供或未提供正確個人資料，{{brand}} Taiwan將無法提供您蒐集目的範圍內之相關服務。</li>
            <li>本人已充分知悉上述告知事項，並同意貴公司基於上述蒐集目的蒐集、處理、利用本人之個人資料。</li>
            <li>【請注意，此處點選提交，代表您已經閱畢「{{brand}} Taiwan會員申請」之個資告知事項，並願以電子文件方式同意本公司蒐集、處理、以及利用您的個人資料，若您不同意「{{brand}} Taiwan會員申請」蒐集、處理、以及利用您的個人資料，請關閉此頁面，此次申請將不會成功。】</li>
          </ul> -->
          <cube-button id="sign-in-button" class="item" :light="true"  style="transform: translateY(0px); margin: 20px auto; " @click="closeTerms" >確認</cube-button>
        </div>
        <!-- <cube-checkbox v-model="checked" position="center" shape="square" :hollow-style="true">
          我已閱讀並同意
        </cube-checkbox> -->
      </div>
      <br><br>
      <!-- 確認按鈕 -->
      <cube-button  :disabled="checked !== true" id="sign-in-button" class="item" :light="true"  style="transform: translateY(0px);" @click="login" >確認</cube-button>

      <!-- Eilis LOGO -->
      <div class="bottom">
        <img src="../img/eilis.png" alt="Smiley face" height="14" width="">
        <div style="transform: scale(0.7)"> 智慧互動助理提供</div>
      </div>
    </div>
    <!-- QR Code -->
    <!-- <div v-if="platform === 'desktop'">
      <div style="border: 1px solid #cbced3; display: inline-block; padding: 20px; padding-bottom: 12px; margin-top: 40px; width: 200px;">
        <qrcode-vue :value="qrcode" size="150" level="H"></qrcode-vue>
      </div>
      <div style="margin-top: 15px;">
        {{ $t('Login.no_service') }}<br>{{ $t('Login.scan_code') }}
      </div>
    </div> -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions } from 'vuex'
import counter from '@/components/data/counter.json'
import axios from 'axios'
/* eslint-disable */
const region = {
    '基隆市':['200仁愛區', '201信義區', '202中正區', '203中山區', '204安樂區', '205暖暖區', '206七堵區'],
    '台北市':["100中正區","103大同區","104中山區","106大安區","105松山區","108萬華區","110信義區","111士林區","112北投區","114內湖區","115南港區","116文山區"],
    '新北市':['234永和區',"220板橋區","234永和區","235中和區","221汐止區","236土城區","244林口區","252三芝區","251淡水區","249八里區","248五股區","247蘆洲區","242新莊區","243泰山區","237三峽區","253石門區","241三重區","239鶯歌區","238樹林區","231新店區","208金山區","207萬里區","233烏來區","232坪林區","222深坑區","223石碇區","224瑞芳區","226平溪區","227雙溪區","228貢寮區"],
    '宜蘭縣':['260宜蘭市', '261頭城鎮', '262礁溪鄉', '263壯圍鄉', '264員山鄉', '265羅東鎮', '266三星鄉', '267大同鄉', '268五結鄉', '269冬山鄉', '270蘇澳鎮', '272南澳鄉', '290釣魚台'],
    '桃園市':['320中壢區', '324平鎮區', '325龍潭區', '326楊梅區', '327新屋區', '328觀音區', '330桃園區', '333龜山區', '334八德區', '335大溪區', '336復興區', '337大園區', '338蘆竹區'],
    '新竹市':['300東區', '300北區', '300香山'],
    '新竹縣':['308寶山鄉','302竹北市', '303湖口鄉', '304新豐鄉', '305新埔鎮', '306關西鎮', '307芎林鄉', '310竹東鎮', '311五峰鄉', '312橫山鄉', '313尖石鄉', '314北埔鄉', '315峨眉鄉'],
    '苗栗縣':['350竹南鎮', '351頭份鎮', '352三灣鄉', '353南庄鄉', '354獅潭鄉', '356後龍鎮', '357通霄鎮', '358苑裡鎮', '360苗栗市', '361造橋鄉', '362頭屋鄉', '363公館鄉', '364大湖鄉', '365泰安鄉', '366銅鑼鄉', '367三義鄉', '368西湖鄉', '369卓蘭鎮'],
    '台中市':['400中區', '401東區', '402南區', '403西區', '404北區', '406北屯區', '407西屯區', '408南屯區', '411太平區', '412大里區', '413霧峰區', '414烏日區', '420豐原區', '421后里區', '422石岡區', '423東勢區', '424和平區', '426新社區', '427潭子區', '428大雅區', '429神岡區', '432大肚區', '433沙鹿區', '434龍井區', '435梧棲區', '436清水區', '437大甲區', '438外埔區', '439大安區'],
    '彰化縣':['500彰化市', '502芬園鄉', '503花壇鄉', '504秀水鄉', '505鹿港鎮', '506福興鄉', '507線西鄉', '508和美鎮', '509伸港鄉', '510員林鎮', '511社頭鄉', '512永靖鄉', '513埔心鄉', '514溪湖鎮', '515大村鄉', '516埔鹽鄉', '520田中鎮', '521北斗鎮', '522田尾鄉', '523埤頭鄉', '524溪州鄉', '525竹塘鄉', '526二林鎮', '527大城鄉', '528芳苑鄉', '530二水鄉'],
    '南投縣':["540南投市","541中寮鄉","542草屯鎮","544國姓鄉","545埔里鎮","546仁愛鄉","551名間鄉","552集集鎮","553水里鄉","555魚池鄉","556信義鄉","557竹山鎮","558鹿谷鄉"],
    '雲林縣':["630斗南鎮","631大埤鄉","632虎尾鎮","633土庫鎮","634褒忠鄉","635東勢鄉","636台西鄉","637崙背鄉","638麥寮鄉","640斗六市","643林內鄉","646古坑鄉","647莿桐鄉","648西螺鎮","649二崙鄉","651北港鎮","652水林鄉","653口湖鄉","654四湖鄉","655元長鄉"],
    '嘉義市':['600西區', '600東區'],
    '嘉義縣':['602番路鄉', '603梅山鄉', '604竹崎鄉', '605阿里山鄉', '606中埔鄉', '607大埔鄉', '608水上鄉', '611鹿草鄉', '612太保市', '613朴子市', '614東石鄉', '615六腳鄉', '616新港鄉', '621民雄鄉', '622大林鎮', '623溪口鄉', '624義竹鄉', '625布袋鎮'],
    '台南市':["704北區", "701東區", "702南區", "700中西區", "710永康區","711歸仁區","712新化區","713左鎮區","714玉井區","715楠西區","716南化區","717仁德區","718關廟區","719龍崎區","720官田區","721麻豆區","722佳里區","723西港區","724七股區","725將軍區","726學甲區","727北門區","730新營區","731後壁區","732白河區","733東山區","734六甲區","735下營區","736柳營區","737鹽水區","741善化區","742大內區","743山上區","744新市區","745安定區", "708安平區", "709安南區"],
    '高雄市':['800新興區', '801前金區', '802苓雅區', '803鹽埕區', '804鼓山區', '805旗津區', '806前鎮區', '807三民區', '811楠梓區', '812小港區', '813左營區', '814仁武區', '815大社區', '820岡山區', '821路竹區', '822阿蓮區', '823田寮區', '824燕巢區', '825橋頭區', '826梓官區', '827彌陀區', '828永安區', '829湖內區', '830鳳山區', '831大寮區', '832林園區', '833鳥松區', '840大樹區', '842旗山區', '843美濃區', '844六龜區', '845內門區', '846杉林區', '847甲仙區', '848桃源區', '849那瑪夏區', '851茂林區', '852茄萣區'],
    '屏東縣':['900屏東市', '901三地門鄉', '902霧台鄉', '903瑪家鄉', '904九如鄉', '905里港鄉', '906高樹鄉', '907鹽埔鄉', '908長治鄉', '909麟洛鄉', '911竹田鄉', '912內埔鄉', '913萬丹鄉', '920潮州鎮', '921泰武鄉', '922來義鄉', '923萬巒鄉', '924崁頂鄉', '925新埤鄉', '926南州鄉', '927林邊鄉', '928東港鎮', '929琉球鄉', '931佳冬鄉', '932新園鄉', '940枋寮鄉', '941枋山鄉', '942春日鄉', '943獅子鄉', '944車城鄉', '945牡丹鄉', '946恆春鎮', '946滿州鄉'],
    '台東縣':['950台東市','951綠島鄉', '952蘭嶼鄉', '953延平鄉', '954卑南鄉', '955鹿野鄉', '956關山鎮', '957海端鄉', '958池上鄉', '959東河鄉', '961成功鎮', '962長濱鄉', '963太麻里鄉', '964金峰鄉', '965大武鄉', '966達仁鄉'],
    '花蓮縣':['970花蓮市', '971新城鄉', '972秀林鄉', '973吉安鄉', '974壽豐鄉', '975鳳林鎮', '976光復鄉', '977豐濱鄉', '978瑞穗鄉', '979萬榮鄉', '981玉里鎮', '982卓溪鄉', '983富里鄉'],
    '金門縣':['890金沙鎮', '891金湖鎮', '892金寧鄉', '893金城鎮', '894烈嶼鄉', '895烏坵鄉'],
    // '連江縣':['南竿鄉', '210北竿鄉', '211莒光鄉', '212東引鄉'],
    // '澎湖縣':['880馬公市', '881西嶼鄉', '882望安鄉', '883七美鄉', '884白沙鄉', '885湖西鄉']
}
let shop = [];
export default {
  data () {
    return {
      shop: [],
      model: {
        name: '',
        // year: '',
        // month: '',
        // day: '',
        remarks: '',
        voucher: '',
        shopNo: '',
        area: '',
        dateValue: '',
        district: '',
        email: '',
        gender: '',
        saleroom: '',
        address: '',
        city: '',
      },
      fields: [
        {
          type: 'input',
          modelKey: 'name',
          label: '姓名',
          props: {
            placeholder: '',
          },
          messages: {
            required: '此項目必填'
          },
          // 是否必填 紅色警示
          rules: {
            required: true
          }
        },
        {
          type: 'checkbox-group',
          modelKey: 'dateValue',
          label: '出生日期',
          props: {
            confirmTxt: '確定',
            placeholder: '',
            options: [],
          },
          messages: {
            required: '此項目必填'
          },
          // 是否必填 紅色警示
          rules: {
            required: true
          }
        },
        {
          type: 'checkbox-group',
          modelKey: 'area',
          label: '區域',
          props: {
            confirmTxt: '確定',
            placeholder: '',
            options: [],
          },
          // 是否必填 紅色警示
          rules: {
            required: true
          }
        },
        {
          type: 'input',
          modelKey: 'address',
          label: '地址',
          props: {
            placeholder: '請輸入地址',
          },
          // 是否必填 紅色警示
          rules: {
            required: false
          }
        },
        {
          type: 'input',
          modelKey: 'email',
          label: 'Email',
          props: {
            placeholder: '請輸入Email',
          },
          // 是否必填 紅色警示
          rules: {
            required: false
          }
        },
        {
          type: 'select',
          modelKey: 'gender',
          label: '性別',
          props: {
            confirmTxt: '確定',
            title: '請選擇',
            placeholder: '請輸入性別',
            options: ['男','女'],
          },
          // 是否必填 紅色警示
          rules: {
            required: false
          }
        },
        {
          type: 'select',
          modelKey: 'city',
          label: '分店縣市',
          props: {
            confirmTxt: '確定',
            title: '請選擇',
            placeholder: '請輸入',
            options: [],
            //options: store.map(el => el.name),
          },
          // 是否必填 紅色警示
          rules: {
            required: false
          }
        },
        {
          type: 'select',
          modelKey: 'district',
          label: '分店區域',
          props: {
            confirmTxt: '確定',
            title: '請選擇',
            placeholder: '請輸入',
            options: this.selectCity,
            //options: store.map(el => el.name),
          },
          // 是否必填 紅色警示
          rules: {
            required: false
          }
        },
        {
          type: 'select',
          modelKey: 'saleroom',
          label: '分店',
          props: {
            confirmTxt: '確定',
            title: '請選擇',
            placeholder: '請輸入',
            options: [],
            //options: store.map(el => el.name),
          },
          // 是否必填 紅色警示
          rules: {
            required: false
          }
        },
        {
          type: 'input',
          modelKey: 'voucher',
          label: '優惠碼',
          props: {
            placeholder: '請輸入優惠碼',
          },
          // 是否必填 紅色警示
          rules: {
            required: false
          }
        },
        {
          type: 'input',
          modelKey: 'remarks',
          label: '備註',
          props: {
            placeholder: '請輸入備註',
          },
          // 是否必填 紅色警示
          rules: {
            required: false
          }
        }
      ],
      chosenCity: '',
      city: '',
      district: '',
      shoplist: [],
      openLaw: false,
      checked: false,
      // day: '',
      loading: true,
      name: '',
      phoneVerify: false,
      PhoneNumber: this.$route.query.phone,
      loading: false,
      option: Object.keys(region),
      verifyId: '',
      verCode: '',
      Service: this.$route.params.service,
      visibility: false,
      alert: false,
      alertmessage: '電話號碼還未輸入喔！',
      countryCode: '+886',
      channelData: {
        AuthToken: this.$route.query.Auth,
        Service: this.$route.params.service,
        PhoneNumber: '',
        Name: this.$route.query.Name ? this.$route.query.Name : '',
        PageID: this.$route.query.Page,
        trigger: this.$route.query.trigger,
        transactionId: this.$route.query.transactionId,
        Img: this.$route.query.Img,
      },
      countries:[
        {
          text: 'Taiwan',
          value: '+886',
          example: '0912345678'
        },
        {
          text: 'Cambodia' ,
          value: '+855',
          example: '091234567'
        },
        {
          text: 'Malaysia',
          value: '+60',
          example: '0123456789'
        }
      ],
    }
  },
  watch: {
    // saleroomSelect(val) {
    //   console.log(val);
    //   this.fields[6].props.options = this.shop;
    //   // console.log(val);
    //   // console.log(counter.filter(el => el.counter === this.brand)[0].items.filter(el => el.name === val)[0].id);
    //   // if (val !== '') {
    //   //   const saleroom = counter.filter(el => el.counter === this.brand)[0].items.filter(el => el.name === val)[0].id;
    //   //   // console.log(saleroom);
    //   //   this.saleRoom = saleroom;
    //   //   // this.model.saleroom = room
    //   //   // console.log(this.model.saleroom);
    //   // }
    // },
    // countySelect(val) {
    //   // console.log(val);
    //   this.fields[5].props.options = region[this.model.county];
    //   // console.log(region[this.model.county]);
    // },
    // selectYear(val) {
    //   this.model.month = '';
    //   if (val) {
    //     this.fields[2].props.disabled = false;
    //   }
    //   else {
    //     this.fields[2].props.disabled = true;
    //   }
    // },
    // selectMonth(val) {
    //   this.model.day = '';
    //   if (val) {
    //     this.fields[3].props.disabled = false;
    //     const monthNum = (val[0] === '0') ? parseInt(val[1]) : parseInt(val);
    //     let dayList = [];
    //     const monthDay = new Date(this.selectYear, val, 0).getDate();
    //     for (let i = 0; i < monthDay; i += 1) {
    //       const day = (i + 1) < 10 ? `0${i + 1}` : (i + 1).toString();
    //       dayList.push(day);
    //     }
    //     this.fields[3].props.options = dayList;
    //   }
    //   else {
    //     this.fields[3].props.disabled = true;
    //   }
    // }
  },
  computed: {
    ...mapGetters({
      eilisProfile: 'eilisProfile',
      info: 'info',
      personalData: 'personalData',
      brandData: 'brandData',
      OcardUser: 'OcardUser',
      shopDetail: 'shopDetail'
    }),
    storeShopContent: {
      get () { return this.shopDetail },
      set(value) { this.updateShopContent({ shopDetail: value }); },
    },
    individualData: {
      get () { return this.personalData },
      set(value) { this.storepersonal({ personal: value }); },
    },
    storeBrandData: {
      get () { return this.brandData },
      set(value) { this.updateBrandData({ brand: value }); },
    },
    modelEmail() {
      return this.model.email
    },
    // saleroomSelect() {
    //   return this.model.saleroom;
    // },
    countySelect() {
      return this.model.county;
    },
    transform() {
      // watch 無法監聽物件, computed不能修改component的值, 所以顯示會用placeholder
      if (this.channelData.Service === 'LINE') {
        return this.channelData.AuthToken.substr(5,10);
      } else {
        return this.channelData.AuthToken
      }
    },
    token: {
      get () { return this.info.token },
      set(value) { this.updatetoken({ price: value }); },
    },
    phone: {
      get () { return this.info.phone },
      set(value) { this.updatephone({ phone: value }); },
    },
    options() {
      if (this.county) {
        return region[this.county];
      }
      return [];
    },
    // selectYear() {
    //   return this.model.year;
    // },
    // selectMonth() {
    //   return this.model.month;
    // },
  },
  mounted() {
    // this.$loading.hide();
    console.log(this.eilisProfile);
    console.log(this.brandData);
    if (!this.shopDetail && localStorage.getItem('shopcontent')) {
      this.storeShopContent = JSON.parse(localStorage.getItem('shopcontent'))
      setTimeout(() => {
        this.model.saleroom = this.shopDetail ? this.shopDetail.shopName : JSON.parse(localStorage.getItem('shopcontent')).shopName;
        this.model.shopNo = this.shopDetail ? this.shopDetail.posId : JSON.parse(localStorage.getItem('shopcontent')).posId
      },500)
    } else {
        // shopName: this.model.saleroom,
      this.model.saleroom = this.shopDetail.shopName;
      this.model.shopNo = this.shopDetail.posId
    }
    if (this.eilisProfile.shop !== 0) {
      this.getShops({accountId: this.eilisProfile.accountId, zip: '',})
      .then((res) => {
        console.log(res);
        this.shop = res.data.result
        let a = []
        let newAddress = {}
        for (let i = 0; i < this.shop.length; i++) {
          // let a = []
          if (this.shop[i].county !== null) {
            const cityName = this.shop[i]['county'];
            if (cityName !== null) {
              newAddress[cityName] = []
            }
            if (i === this.shop.length - 1) {
              for (let e = 0; e < this.shop.length; e += 1) {
                const cityName2 = this.shop[e]['county'];
                const district = this.shop[e]['district']
                if (district !== null) {
                  if (newAddress[cityName2].indexOf(district) === -1) {
                    newAddress[cityName2].push(district)
                  }
                }
              }
            }
          }
        }
        console.log(newAddress);
        this.city = Object.keys(newAddress)
        this.district = newAddress
        this.shoplist = res.data.result
        console.log(this.city);

        //console.log(this.fields[6].props.options.map(el => el.shopName));
        //// this.fields[6].props.options = this.shop;
      })
    }
    // console.log(this.info.clientId);
  },
  methods: {
    ...mapMutations({
      updatephone: 'updatephone',
      storepersonal: 'storepersonal',
      updateBrandData: 'updateBrandData',
      updateShopContent: 'updateShopContent',
    }),
    ...mapActions({
      checkAccountGetClientID: 'checkAccountGetClientID',
      register: 'register',
      getShops: 'getShops',
      getConfig: 'getConfig', // 註冊成功要品牌資料
      checkUserByToken:'checkUserByToken', // 註冊成功要個人資料
      Encrypt: 'Encrypt', // 加密


      readMember: 'readMember',
      registerEilis: 'registerEilis',
      addMember: 'addMember',
      updateMember: 'updateMember',
      updateMemberEilis: 'updateMemberEilis'
    }),
    validateHandler(result) {
      this.validity = result.validity
      this.valid = result.valid
      console.log('validity', result.validity, result.valid, result.dirty, result.firstInvalidFieldIndex)
    },
    showCityPicker() {
      console.log(this.city);
      let arr = []
      for (let i = 0;i< this.city.length; i++ ) {
        arr[i] = {
          text: this.city[i],
          value: this.city[i],
        }
      }
      console.log(arr);
      this.$createPicker({
        title: '選擇分店縣市',
        data: [arr],
        confirmTxt: '確定',
        onSelect: this.selectCity
      }).show()
    },
    selectCity(selectedVal, selectedIndex, selectedTxt) {
      console.log(selectedVal);
      this.chosenCity = selectedVal[0]
      this.model.city = selectedVal[0]
      this.district[selectedVal[0]]
    },
    showDistrictPicker() {
      if (this.model.district) {
        this.model.district = '';
      }
      let section = this.district[this.chosenCity]
      // this.district = this.district[this.chosenCity]
      let arr = []
      for (let i = 0; i < section.length; i++ ) {
        arr[i] = {
          text: section[i],
          value: section[i],
        }
      }
      console.log(arr);
      this.$createPicker({
        title: '選擇分店區域',
        data: [arr],
        confirmTxt: '確定',
        onSelect: this.selectDistrict,
      }).show()
      // this.model.district = this.district[0];
    },
    selectDistrict(selectedVal, selectedIndex, selectedTxt) {
      console.log(selectedVal);
      this.model.district = selectedVal[0]
    },
    showShopPicker(val) {
      console.log(this.shop);
      // 如果該品牌分佈縣市超過五個
      if (this.city.length > 5) {
        let shop = this.shop.filter(el => el['district'] === val);
        let arr = []
        for (let i = 0;i< shop.length; i++ ) {
          arr[i] = {
            text: shop[i].shopName,
            value: shop[i].shopNo,
          }
        }
        console.log(arr);
        this.$createPicker({
          title: '選擇分店',
          data: [arr],
          confirmTxt: '確定',
          onSelect: this.selectShop
        }).show()
      } else {
        let arr = []
        for (let i = 0;i< this.shop.length; i++ ) {
          arr[i] = {
            text: this.shop[i].shopName,
            value: this.shop[i].shopNo,
          }
        }
        console.log(arr);
        this.$createPicker({
          title: '選擇分店',
          data: [arr],
          confirmTxt: '確定',
          onSelect: this.selectShop
        }).show()
      }
    },
    selectShop(selectedVal, selectedIndex, selectedTxt) {
      console.log(selectedVal);
      this.model.shopNo = selectedVal
      this.model.saleroom = `${selectedTxt}`
    },
    showAreaPicker() {
      if (this.model.area) {
        this.model.area = ''
      }
      // console.log(region)
      // console.log(Object.values(region))
      console.log(Object.entries(region))
      let arr = []
      let arr1 = []
      let cc = []
      for(let i = 0; i < Object.entries(region).length; i ++) {
        arr[i] = {
          value: Object.entries(region)[i][0],
          text: Object.entries(region)[i][0],
          children: [],
        }
        for (let e = 0; e < arr.length; e ++) {
          for (let q = 0; q < Object.entries(region)[i][1].length; q ++) {
            if (Object.entries(region)[i][0] === arr[e].value) {
              arr[i].children[q] = {
                value: q,
                text: Object.entries(region)[i][1][q]
              }
            }
          }
        }
      }
      console.log(arr);
      this.$createCascadePicker({
        title: '選擇區域',
        data: arr,
        confirmTxt: '確定',
        onSelect: this.selectHandler
      }).show()
    },
    selectHandler(selectedVal, selectedIndex, selectedTxt) {
      console.log(selectedVal)
      console.log(selectedTxt)
      this.model.area = `${selectedTxt[0] + selectedTxt[1]}`
      this.model.address = ''
      // this.$emit('input', selectedVal)
    },
    showDatePicker() {
      if (this.model.dateValue) {
        this.model.dateValue = '';
      }
      if (!this.datePicker) {
        this.datePicker = this.$createDatePicker({
          title: '請選擇',
          min: new Date(1935, 1, 1),
          max: new Date(2020, 9, 20),
          value: new Date(1960,1,1),
          confirmTxt: '確定',
          onSelect: this.dateSelectHandler,
          onCancel: this.cancelHandle
        })
      }
      this.datePicker.show()
    },
    dateSelectHandler(selectedVal) {
      var year = selectedVal.getFullYear().toString();
      var month = (selectedVal.getMonth() + 1) < 10 ? `0${(selectedVal.getMonth() + 1).toString()}` : (selectedVal.getMonth() + 1).toString();
      var day = selectedVal.getDate() < 10 ? `0${selectedVal.getDate().toString()}` : selectedVal.getDate().toString();
      var localeDate = `${year}-${month}-${day}`;
      console.log(localeDate)
      this.model.dateValue = localeDate
    },
    closeDia() {
      this.noneInfo = false;
    },
    closeTerms() {
      this.openLaw = false;
    },
    openTerms() {
      this.openLaw = true;
    },
    showAlert(contents) {
      this.$createDialog({
        type: 'alert',
        title: '小提示',
        content: contents,
        icon: 'cubeic-alert',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
      }).show()
    },
    showAlert_script() {
      this.$createDialog({
        type: 'alert',
        title: '小提示',
        content: '僅允許中英文或空格',
        icon: 'cubeic-alert',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
      }).show()
    },
    login(val) {
      this.$loading.show();
      console.log(this.model.address);
      console.log(this.model.area);
      console.log(this.model.area.substr(6));
      console.log(this.model.area.substr(3,3));
      console.log(this.model.email.search(emailRule));
      console.log(this.eilisProfile.email);
      console.log(this.eilisProfile);
      // return;
      // 取100-500之間,
      const emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
      const time = Math.floor(Math.random()* (500-100+1))+100
      // 空格：/^\w+(?:\s+\w+)*$/
      var txt = new RegExp(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5/^\w+(?:\s+\w+)*$/]/g,);
      if (txt.test(this.model.name)) {
        this.showAlert_script();
        this.$loading.hide();
        return;
      }
      if (this.model.name === '') {
        const contents = '姓名還未填'
        this.showAlert(contents);
        this.$loading.hide();
        return;
      } else if (this.model.dateValue === '') {
        const contents = '生日忘記填喔！'
        this.showAlert(contents);
        this.$loading.hide();
        return;
      } else if (this.info.phone === '') {
        const contents = '遺失電話，請關閉頁面且重新註冊！'
        this.showAlert(contents);
        this.$loading.hide();
        return;
      } else if (this.eilisProfile.address === 1 && (this.model.address === '' || this.model.area === '')) {
        const contents = '尚未填寫地址'
        this.showAlert(contents);
        this.$loading.hide();
        return;
      } else if (this.eilisProfile.gender === 1 && this.model.gender === '') {
        const contents = '尚未填寫性別'
        this.showAlert(contents);
        this.$loading.hide();
        return;
      } else if (this.eilisProfile.email === 1 && this.model.email === '') {
        const contents = '尚未填寫Email'
        this.showAlert(contents);
        this.$loading.hide();
        return;
      } else if (this.eilisProfile.email === 1 && this.model.email.search(emailRule) !== 0) {
        const contents = 'Email格式有誤'
        this.showAlert(contents);
        this.$loading.hide();
        return;
      }
      else if (this.eilisProfile.shop === 1 && this.model.saleroom === '') {
        const contents = '尚未填寫店櫃'
        this.showAlert(contents);
        this.$loading.hide();
        return;
      }
      else if (this.eilisProfile.remarks === 1 && this.model.remarks === '') {
        const contents = '尚未填寫備註'
        this.showAlert(contents);
        this.$loading.hide();
        return;
      }
      else if (this.eilisProfile.voucher === 1 && this.model.voucher === '') {
        const contents = '尚未填寫禮物碼'
        this.showAlert(contents);
        this.$loading.hide();
        return;
      }
      else {
        this.register({ service: this.$route.params.service, token: this.$route.query.Auth , auth: this.eilisProfile.auth,
        accountId: this.eilisProfile.accountId,
        clientId: this.eilisProfile.clientId,
        phone: this.info.phone,
        name: this.model.name.trim(),
        birth: this.model.dateValue,
gender: this.model.gender && this.model.gender === '男' ? 'male' : 'female',
        address: this.model.address,
        email:this.model.email,
        country: this.info.countryCode,
        county: this.model.area,
        district: this.model.area.substr(6),
        zipcode: this.model.area.substr(3,3),
        shopName: this.model.saleroom,
        shopNo: this.shopDetail ? this.model.shopNo : this.model.shopNo[0],
        remarks: this.model.remarks,
        voucherCode: this.model.voucher,
        profileImg: this.channelData.Img})
        .then((res) => {
          console.log(res);
          if (res.data.result && res.data.status === 10020) {
            const A =  this.getConfig({accountId: this.eilisProfile.accountId})
            const B =  this.checkUserByToken({service: this.$route.params.service, auth: this.eilisProfile.auth, token: this.$route.query.Auth, clientId: this.eilisProfile.clientId, accountId: this.eilisProfile.accountId})
            Promise.all([A, B]).then(values => {
              console.log(values);
              // 加密暫存個人資料
              this.Encrypt(values[1].data.result).then((res) => {
                localStorage.setItem('personalInfo', res)
              })
              this.storeBrandData = values[0].data.result;
              this.individualData = values[1].data.result;
              localStorage.setItem('brandColor', this.storeBrandData.brandColor)
              localStorage.setItem('eiAuth', values[0].data.result.auth)
              console.log(this.individualData);
              console.log(this.storeBrandData);
              this.$loading.hide();
              this.$router.push(`/mainbottom/${this.$route.params.service}?Auth=${this.channelData.AuthToken}`);
              this.$loading.hide();
            })
          }
          if (res.data.status === 300) {
            // 300 = 多筆資料
            // 超過ㄧ筆資料的條件下
            const contents = '為了維護您的會員權益請至店櫃完成升等'
            this.showAlert(contents);
            this.$loading.hide();
            const url = 'https://script.google.com/macros/s/AKfycbxTrRxfdE3ZqhCVx3E6l2m0S7Zi-KHKgTHGiREY3vNy2KvNVQA/exec';
            const time = new Date(Date.now()).toLocaleString();
            let name = this.model.name;
            let phone = this.info.phone;
            let birth = this.model.year + this.model.month + this.model.day;
            let Id = this.channelData.AuthToken;
            let oldId = ''
            const data = {
              time,
              name,
              phone,
              birth,
              Id,
              oldId
            }
            axios.get(url, {params: data})
            .then((res) => {
              // console.log(res);
            })
          }
          else if (Object.values(res.data.result).length === 15) {
            // 只有ㄧ筆資料的條件下
            // console.log('626');
            // console.log(res.data.result.custid);
            // console.log(this.info.phone);
            this.registerEilis({
              service: this.channelData.Service,
              clientId: this.info.clientId,
              token: this.channelData.AuthToken,
              phone:  this.info.phone,
              userId: custid,
              name: this.model.name,
              birth: this.model.year + this.model.month + this.model.day,
              profileImg: this.channelData.Img ? this.channelData.Img : '',
              })
              .then((res) => {
                this.updateMemberEilis({clientId: this.info.clientId,phone: this.info.phone, gender: gender, detail: cardkind, address: address, email: email})
                .then(() => {
                  // this.$router.push(`/mainbottom/${this.$route.params.service}?Auth=${this.channelData.AuthToken}&Page=${this.channelData.PageID}`);
                  this.updateMember({
                    brand: this.equal,
                    custid: custid,
                    name: this.model.name,
                    phone: this.info.phone,
                    lineid: this.channelData.Service === 'LINE' ? this.channelData.AuthToken : null,
                    fbid: this.channelData.Service === 'LINE' ? null : this.channelData.AuthToken ,
                  })
                  .then((res) => {
                    this.$router.push(`/mainbottom/${this.$route.params.service}?Auth=${this.channelData.AuthToken}&Page=${this.channelData.PageID}`);
                  })
                })
              })
          } else {
            // 200 查無資料
            // this.requInfo = true;
            // this.$loading.hide();
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  li {
    font-size: 13px;
    line-height: 25px;
    word-break: break-all;
  }
  .scroll-list-wrap {
    z-index: 1000000000;
    text-align: left;
    background-color: #ffffff;
    word-break: break-all;
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
    font-size: 0.8cm;
    transform: rotate(0deg); // fix 子元素超出边框圆角部分不隐藏的问题
    // overflow: hidden;
    // transform: scale();
  }
  .mobile-wrap {
    margin: auto;
    width: 300px;
    height: 1300px;
    width: 100%;
    overflow: scroll;
    position: relative;
  }
  // .mobile-wrap:before {
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // content: '';
    // position: absolute;
    // background: rgba(90, 93, 165, .8);
    // background: linear-gradient(to bottom, rgba(90, 93, 165, 1), rgba(0, 0, 0, .7))
  // }
  .info {
    // border: 1px solid rgb(211, 211, 211);
    border-radius: 0px;
    z-index: 1000;
    width: 80%;
    margin: 0 auto;
    z-index: 10000;
    text-align: left;
    background-color: #ffffff;
    margin-top: 130px;
    height: 420px;
    font-size: 0.5cm;
    border-radius: 3px;
    overflow-y: auto;
    // transform:translate(-50%,-50%);
    animation: bomaction 0.15s linear forwards;
    // transform: rotate(0deg); // fix 子元素超出边框圆角部分不隐藏的问题
  }
  @keyframes bomaction {
    0% {
      -webkit-transform: scale(0.1)  rotate(0deg);
              transform: scale(0.1)  rotate(0deg);
      top:0%;
    }
    46% {
      top:52%;
    }
    48% {
      top:48%;
    }
    50% {
      -webkit-transform: scale(0.5)  rotate(0deg);
              transform: scale(0.5)  rotate(0deg);
      top:52%;
    }
    54% {
      top:52%;
    }
    56% {
      top:48%;
    }
    57% {
      -webkit-transform: scale(0.9)  rotate(0deg);
              transform: scale(0.9)  rotate(0deg);
      top:48%;
    }
    75% {
      -webkit-transform: scale(0.95)  rotate(0deg);
              transform: scale(0.95)  rotate(0deg);
      top:48%;
    }
    90% {
      -webkit-transform: scale(1.15)  rotate(0deg);
              transform: scale(1.15)  rotate(0deg);
      top:48%;
    }
    100% {
      -webkit-transform: scale(1) translateX(0px) rotate(0deg);
              transform: scale(1) translateX(0px) rotate(0deg);
      top:50%;
    }
  }
  .menu {
    padding-left: 0!important;
    padding-right: 0!important;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    max-width: 540px;
    min-width: 300px;
    height: 750px;
    background-color: #ffffff;
    /* border: 1px solid rgb(170, 170, 170); */
    /* border:1px solid #8a8a8a; */
  }
  .pic {
    position: relative;
    z-index: 1;
    transform: scale(0.6);
    // top: 30px;
    margin: 0px;
    margin-top: 40px;
    margin-left: 0px;
  }
  .item {
    // z-index: 1;
    width: 80%;
    max-width: 420px;
    min-width: 200px;
    margin: 0 auto 20px;
  }
  section {
    position: relative;
    background-color: none;
    width: 100%;
    height: 100vh;
  }
  #curve {
    z-index: 1000;
    position: relative;
    transform: scale(4) rotate(6deg);
    bottom: 500px;
    left: 200px;
    width: 100%;
  }
  #curve path {
    fill: rgb(76, 182, 14);
  }
  .bottom {
    font-size: 5px;
    position: relative;
    bottom: -20px;
    right: 0px;
    left: 0px;
  }
</style>
